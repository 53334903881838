// import './components/formComponent';
import './components/scrollToTop';

// import textFit from 'textfit';



// Main js file
let isTouch = false;
if ('ontouchstart' in document.documentElement) {
    isTouch = true;
}
document.body.className += isTouch ? ' touch' : ' no-touch';

const mainWrap = document.querySelector('main');



function toggle(selector,cls,targetElem = selector){
    const target = document.querySelectorAll(selector);
    if(targetElem !== selector){
        const targetElemWithCls = document.querySelector(targetElem);
        target.forEach(text => {
            text.addEventListener('click', function(){
                targetElemWithCls.classList.toggle(cls);
            })
        })
    } else {
        if(target){
            target.forEach(text => {
                text.addEventListener('click', function(){
                    this.classList.toggle(cls);
                })
            })
        }
    }
}

function closeWhenClickOutside(selector,cls,targetElem = selector){
    const specifiedElement = document.querySelectorAll(selector);
    if(targetElem !== selector){
        const targetElemWithCls = document.querySelector(targetElem);
        document.addEventListener('click', function(event) {
            specifiedElement.forEach(elem => {
                const isClickInside = elem.contains(event.target);
                console.log(isClickInside);
                if (!isClickInside) {
                    targetElemWithCls.classList.remove(cls);
                }
            });
        });
    } else {
        document.addEventListener('click', function(event) {
            specifiedElement.forEach(elem => {
                const isClickInside = elem.contains(event.target);

                if (!isClickInside) {
                    elem.classList.remove(cls);
                }
            });
        });
    }
}

toggle('.js-lang-switcher','is-open');
closeWhenClickOutside('.js-lang-switcher','is-open');



document.addEventListener("DOMContentLoaded", function() {
    var lang = document.documentElement.lang;

    if(!!lang){
        var langHidden = (lang === 'fr') ? 'nl' : 'fr';
        var elementsToRemove = document.querySelectorAll('[data-lang="'+langHidden+'"]');
        Array.prototype.forEach.call(elementsToRemove, function(el) {
            el.remove();
        });

        if(lang === 'nl'){
            var elementsToRemoveNL = document.querySelectorAll('.hiddenOnNL');
            Array.prototype.forEach.call(elementsToRemoveNL, function(el) {
                el.remove();
            });
        }
        if(lang === 'be'){
            var elementsToRemoveBE = document.querySelectorAll('.hiddenOnBE');
            Array.prototype.forEach.call(elementsToRemoveBE, function(el) {
                el.remove();
            });
        }
        if(lang === 'fr'){
            var elementsToRemoveFR = document.querySelectorAll('[data-lang="be"]');
            Array.prototype.forEach.call(elementsToRemoveFR, function(el) {
                el.remove();
            });
        }

    }
});