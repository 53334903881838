window.topFunction = function (element = document.documentElement, to = 0, duration = 1000) {

    const start = element.scrollTop;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;

    const animateScroll = (() => {

        currentTime += increment;

        const val = Math.easeInOutQuad(currentTime, start, change, duration);

        element.scrollTop = val;

        if (currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    });

    animateScroll();
};

Math.easeInOutQuad = function (t, b, c, d) {

    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
};


window.onscroll = function () {
    scrollFunction()
};

function scrollFunction() {
    let btnToTop = document.getElementById("myBtn");
    if (!!btnToTop) {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            btnToTop.style.display = "block";
        } else {
            btnToTop.style.display = "none";
        }
    }
}

// Ex: topFunction(document.documentElement);